<template>
  <v-btn @click="changePassword()" outlined color="warning">
    <v-icon left>mdi-pencil</v-icon>
    Modifier mon mot de passe
  </v-btn>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      cognitoToken: (state) => state.account.cognito.signInUserSession.accessToken.jwtToken,
    }),
  },
  methods: {
    changePassword: function() {
      this.$router.push({
        name: "ChangePwd",
      });
    }
  }
};
</script>
